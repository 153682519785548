<template>
  <div class="d-flex justify-content-between">
    <div class="">
      <b-button
        @click="downloadBulk"
        variant="primary"
        class="mr-2"
        v-if="selectedBatches.length"
        >Download selected</b-button
      >
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "CodeGeneratorPagination",
  computed: {
    currentPage: {
      get() {
        return this.$store.state.codeGeneratorTable.currentPage;
      },
      set(value) {
        this.$store.commit("codeGeneratorTable/SET_CURRENT_PAGE", value);
        this.$store.dispatch("codeGeneratorTable/getCodeBatches");
      },
    },
    totalRows() {
      return this.$store.state.codeGeneratorTable.totalRows;
    },
    perPage() {
      return this.$store.state.codeGeneratorTable.perPage;
    },
    selectedBatches() {
      return this.$store.state.codeGeneratorTable.batches.filter((batch) =>
        Boolean(batch.isSelected)
      );
    },
  },
  methods: {
    downloadBulk() {
      this.selectedBatches.forEach((batch) => {
        this.download(batch);
      });
    },
    async download(item) {
      try {
        this.downloading = true;
        const res = await this.$axios.get("/codes/export/" + item.id, {
          responseType: "blob",
        });
        this.downloadFile(res.data, "labels_" + item.name);
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped></style>
