<template>
  <b-modal
    id="edit-code-batch"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetState"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Edit label batch</h4>
    </template>

    <div class="d-flex align-items-center info-box info mb-4">
      <i class="fas fa-info-circle icon mr-3"></i>
      <p class="mb-0">
        The user obtains loyalty points when entering the code on the label. Use
        the input fields below to select the product and the number of labels
        you want to create.
      </p>
    </div>

    <validation-observer ref="createCodeBatch" class="d-flex flex-wrap w-100">
      <validation-provider
        tag="div"
        name="name"
        rules=""
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Name</span>
        <b-form-input
          v-model="name"
          type="text"
          size="sm"
          :disabled="requesting"
        ></b-form-input>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>

      <div class="d-flex col-12 align-items-end justify-content-between p-0">
        <div class="col-7 d-flex flex-column">
          <span class="col-12 pl-0 ml-0"
            >Selected product: <strong>{{ batch.product.name }}</strong></span
          >
          <span class="col-12 pl-0 ml-0"
            >Current loyalty points value for selected product:
            <strong>{{ batch.product.tokens }}</strong></span
          >
        </div>
        <div class="d-flex col-5 justify-content-end">
          <b-input-group class="col-6 px-0 align-self-end">
            <b-input-group-append>
              <div class="m-input__icon left">
                <i class="far fa-search"></i>
              </div>
            </b-input-group-append>
            <b-form-input
              id="search"
              v-model="codeSearchQuery"
              type="text"
              size="sm"
              placeholder="Search codes"
              class="border-left-0 pl-0 py-2 mr-2"
              debounce="500"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>

      <code-table class="mt-3 w-100" />

      <div class="d-flex col-12 justify-content-between">
        <validation-provider
          tag="div"
          name="numberOfNewLabels"
          rules="required"
          class="d-flex flex-column flex-row mb-3 col-6 pl-0"
          v-slot="{ errors }"
        >
          <div class="d-flex flex-row align-items-center">
            <span>I want to create </span>
            <div class="d-flex flex-column col-4">
              <b-form-input
                v-model="numberOfNewLabels"
                type="number"
                size="sm"
                :disabled="requesting"
                class="col-12 ml-1 py-2"
              ></b-form-input>
            </div>
            <span> new labels</span>
          </div>
          <span class="text-danger error">{{ errors[0] }}</span>
        </validation-provider>
        <code-table-pagination class="col-6" />
      </div>
    </validation-observer>

    <template #modal-footer="{ close }">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="col-6">
          <b-form-checkbox v-model="sentForPrinting"
            >Was sent to printing
          </b-form-checkbox>
        </div>
        <div class="col-6 justify-content-end">
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="secondary"
              class="mr-2"
              :disabled="requesting"
              @click="close"
              >Close</b-button
            >
            <b-button variant="primary" :disabled="requesting" @click="validate"
              >Save
            </b-button>
          </div>
        </div>
      </div>
    </template>
    <deactivate-code />
    <assign-code />
  </b-modal>
</template>

<script>
import CodeTable from "./edit-modal/CodeTable";
import DeactivateCode from "./edit-modal/DeactivateCode";
import AssignCode from "./edit-modal/AssignCode";
import CodeTablePagination from "./edit-modal/CodeTablePagination";
export default {
  name: "EditCodeBatch",
  components: { CodeTablePagination, AssignCode, DeactivateCode, CodeTable },
  computed: {
    requesting() {
      return this.$store.state.codeGeneratorTable.requesting;
    },
    products() {
      return this.$store.state.codeGeneratorTable.products;
    },
    codePatterns() {
      return this.$store.state.codeGeneratorTable.codePatterns;
    },
    batch() {
      return this.$store.state.codeGenerator.batch;
    },
    name: {
      get() {
        return this.$store.state.codeGenerator.batch.name;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "name",
          value,
        });
      },
    },
    product: {
      get() {
        return this.$store.state.codeGenerator.batch.product;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "product",
          value,
        });
      },
    },
    numberOfLabels: {
      get() {
        return this.$store.state.codeGenerator.batch.quantity;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "quantity",
          value,
        });
      },
    },
    numberOfNewLabels: {
      get() {
        return this.$store.state.codeGenerator.batch.numberOfNewLabels;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "numberOfNewLabels",
          value,
        });
      },
    },
    codePattern: {
      get() {
        return this.$store.state.codeGenerator.batch.codePattern;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "codePattern",
          value,
        });
      },
    },
    sentForPrinting: {
      get() {
        return this.$store.state.codeGenerator.batch.sentForPrinting;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "sentForPrinting",
          value,
        });
      },
    },

    codeSearchQuery: {
      get() {
        return this.$store.state.codeGeneratorTable.codesSearchQuery;
      },
      set(value) {
        this.$store.commit("codeGeneratorTable/SET_CODES_CURRENT_PAGE", 1);
        this.$store.commit("codeGeneratorTable/SET_CODES_SEARCH_QUERY", value);
        this.$store.dispatch(
          "codeGeneratorTable/getSelectedBatchCodes",
          this.batch.id
        );
      },
    },
  },
  methods: {
    validate() {
      this.$refs.createCodeBatch.validate().then(async (success) => {
        if (success) {
          await this.create();
        }
      });
    },
    async create() {
      try {
        this.$store.commit("codeGenerator/SET_REQUESTING", true);
        const res = await this.$store.dispatch("codeGenerator/updateBatch");
        this.$store.commit("codeGeneratorTable/UPDATE_BATCH", res.data.data);
        this.$bvModal.hide("edit-code-batch");
        this.$bvToast.toast("Batch updated", {
          title: "Code Generator",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error accrued while creating the reward", {
          title: "Code Generator",
          variant: "danger",
        });
      } finally {
        this.$store.commit("codeGenerator/SET_REQUESTING", false);
      }
    },
    resetState() {
      this.$store.commit("codeGeneratorTable/SET_CODES_CURRENT_PAGE", 1);
      this.$store.commit("codeGeneratorTable/SET_CODES_SEARCH_QUERY", "");
      this.$store.commit("codeGenerator/RESET_STATE", "");
    },
  },
};
</script>

<style scoped></style>
