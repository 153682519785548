<template>
  <b-modal
    id="assign-code"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetState"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Assign code to customer</h4>
    </template>

    <div class="d-flex w-100">
      <span class="col-6">
        Selected code: <strong>{{ selectedCode.code }}</strong>
      </span>
    </div>
    <div class="col-12 mt-1">
      Product:
      <strong>{{ batch.product.name }} | {{ batch.product.sku }}</strong>
      <span class="ml-2">
        (current token value: <strong>{{ batch.product.tokens }}</strong>
        <img
          :src="require('@/assets/images/butterlfy-icon.png')"
          class="token-icon"
          v-b-tooltip.hover="'Number of butterflies'"
        />)
      </span>
    </div>

    <validation-observer ref="assignProvider" class="d-flex flex-wrap w-100">
      <validation-provider
        tag="div"
        name="user"
        rules="required"
        class="d-flex flex-column mb-3 col-12 pl-0 mt-3"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Customer</span>
        <multiselect
          v-model="user"
          :options="customers"
          placeholder="Type to search"
          searchable
          :internal-search="false"
          @search-change="searchCustomers"
          :loading="isLoadingCustomers"
        >
          <template slot="singleLabel" slot-scope="props">
            <span
              >{{ props.option.firstName }} {{ props.option.lastName }}</span
            >
            <span class="small ml-2">{{ props.option.email }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span
                >{{ props.option.firstName }} {{ props.option.lastName }}</span
              >
              <span class="small ml-2">{{ props.option.email }}</span>
            </div>
          </template>
        </multiselect>
        <span class="text-danger error">{{
          errors[0]
        }}</span></validation-provider
      >
      <validation-provider
        tag="div"
        name="cause"
        rules="required"
        class="d-flex flex-column mb-3 col-12 pl-0 mt-3"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Reason</span>
        <b-form-select v-model="cause" size="sm" class="col-12 py-3">
          <b-form-select-option
            v-for="(deactivationReason, index) in deactivationReasons"
            :key="index"
            :value="deactivationReason"
          >
            {{ deactivationReason.value }}
          </b-form-select-option>
        </b-form-select>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="comment"
        rules="required"
        class="d-flex flex-column mb-3 col-12 pl-0 mt-3"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Comment</span>
        <b-form-textarea v-model="comment" class="col-12" />
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>
    <p class="d-flex align-items-center info-box info">
      <i class="fas fa-info-circle icon mr-3"></i>
      <span>
        <b> Changes will take effect immediately!</b>
      </span>
    </p>
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center w-100">
        <div class="d-flex align-items-center ml-auto">
          <b-button
            variant="secondary"
            class="mr-2"
            :disabled="requesting"
            @click="close"
            >Close
          </b-button>
          <b-button variant="primary" :disabled="requesting" @click="validate"
            >Assign code
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { get } from "../../../../utils/crud";

export default {
  name: "AssignCode",
  data() {
    return {
      deactivationReasons: [
        {
          id: 1,
          value: "Reclamation",
        },
        {
          id: 2,
          value: "X Code",
        },
        {
          id: 3,
          value: "Missing code",
        },
        {
          id: 4,
          value: "Unreadable code",
        },
        {
          id: 5,
          value: "Other",
        },
      ],
      customers: [],
      isLoadingCustomers: false,
      searchQuery: "",
    };
  },
  created() {
    this.debounceSearch = this._.debounce(async () => {
      this.isLoadingCustomers = true;
      try {
        const customers = await get(
          "search/clients?search=" + this.searchQuery
        );
        this.customers = customers["data"]["data"];
      } finally {
        this.isLoadingCustomers = false;
      }
    }, 200);
    this.debounceSearch();
  },
  computed: {
    batch() {
      return this.$store.state.codeGenerator.batch;
    },
    selectedCode() {
      return this.$store.state.codeGenerator.selectedCode;
    },
    requesting() {
      return this.$store.state.codeGeneratorTable.requesting;
    },
    cause: {
      get() {
        return this.$store.state.codeGenerator.assignData.cause;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_ASSIGN_FIELDS", {
          field: "cause",
          value,
        });
      },
    },
    comment: {
      get() {
        return this.$store.state.codeGenerator.assignData.comment;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_ASSIGN_FIELDS", {
          field: "comment",
          value,
        });
      },
    },
    user: {
      get() {
        return this.$store.state.codeGenerator.assignData.customer;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_ASSIGN_FIELDS", {
          field: "customer",
          value,
        });
      },
    },
  },

  methods: {
    validate() {
      this.$refs.assignProvider.validate().then(async (success) => {
        if (success) {
          await this.create();
        }
      });
    },
    async create() {
      try {
        this.$store.commit("codeGenerator/SET_REQUESTING", true);
        const res = await this.$store.dispatch("codeGenerator/assignCode");
        this.$store.commit("codeGeneratorTable/UPDATE_CODE", res.data.data);
        this.$bvModal.hide("assign-code");
        this.$bvToast.toast("Code assigned", {
          title: "Code Generator",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error accrued while assigning the code", {
          title: "Code Generator",
          variant: "danger",
        });
      } finally {
        this.$store.commit("codeGenerator/SET_REQUESTING", false);
      }
    },
    async searchCustomers(value) {
      this.searchQuery = value;
      this.debounceSearch();
    },
    resetState() {
      this.$store.commit("codeGenerator/RESET_ASSIGN_STATE");
    },
  },
};
</script>

<style scoped></style>
