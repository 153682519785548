<template>
  <div class="d-flex justify-content-between">
    <div class=""></div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      pills
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "CodeTablePagination",
  computed: {
    currentPage: {
      get() {
        return this.$store.state.codeGeneratorTable.codesCurrentPage;
      },
      set(value) {
        this.$store.commit("codeGeneratorTable/SET_CODES_CURRENT_PAGE", value);
        this.$store.dispatch(
          "codeGeneratorTable/getSelectedBatchCodes",
          this.batch.id
        );
      },
    },
    totalRows() {
      return this.$store.state.codeGeneratorTable.codesTotalRows;
    },
    perPage() {
      return this.$store.state.codeGeneratorTable.codesPerPage;
    },
    batch() {
      return this.$store.state.codeGenerator.batch;
    },
    selectedBatches() {
      return this.$store.state.codeGeneratorTable.batches.filter((batch) =>
        Boolean(batch.isSelected)
      );
    },
  },
  methods: {
    downloadBulk() {
      this.selectedBatches.forEach((batch) => {
        this.download(batch);
      });
    },
    async download(item) {
      try {
        this.downloading = true;
        const res = await this.$axios.get("/codes/export/" + item.id, {
          responseType: "blob",
        });
        this.downloadFile(res.data, "labels_" + item.name);
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
  },
};
</script>

<style scoped></style>
