<template>
  <b-table
    :items="codes"
    :fields="fields"
    :busy="fetchingBatches"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="72vh"
    responsive="true"
    class="m-table__wrapper"
    table-class="m-table"
    thead-tr-class="m-table__header-tr"
    thead-class="hidden_header"
    tbody-class="m-table__body"
    tbody-tr-class="m-table__body-tr"
  >
    <template #cell(actions)="{ item }">
      <div
        class="d-flex w-100 justify-content-end"
        v-if="item.status === 'Active'"
      >
        <span @click="deactivate(item)" class="cursor-pointer mr-3"
          ><u>Deactivate</u></span
        >
        <span @click="assign(item)" class="cursor-pointer"
          ><u>Assign to customer</u></span
        >
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "CodeTable",
  data() {
    return {
      fields: [
        {
          key: "id",
          label: "",
          sortable: true,
          tdClass: "id",
          thClass: "id",
        },
        {
          key: "code",
          label: "",
          sortable: true,
          tdClass: "code",
          thClass: "code",
        },
        {
          key: "status",
          label: "",
          sortable: true,
          tdClass: "status",
          thClass: "status",
        },
        {
          key: "info",
          label: "",
          sortable: true,
          tdClass: "info",
          thClass: "info",
        },
        {
          key: "dateText",
          label: "",
          sortable: true,
        },
        {
          key: "actions",
          label: "",
          sortable: true,
          tdClass: "actions",
          thClass: "actions",
        },
      ],
    };
  },
  computed: {
    batch() {
      return this.$store.state.codeGenerator.batch;
    },
    codes() {
      return this.$store.state.codeGeneratorTable.codes;
    },
    fetchingBatches() {
      return this.$store.state.codeGeneratorTable.requesting;
    },
  },
  methods: {
    deactivate(item) {
      this.$store.commit("codeGenerator/SET_SELECTED_CODE", item);
      this.$bvModal.show("deactivate-code");
      console.log(item);
    },
    assign(item) {
      this.$store.commit("codeGenerator/SET_SELECTED_CODE", item);
      this.$bvModal.show("assign-code");
    },
  },
};
</script>

<style>
.hidden_header {
  display: none;
}

/deep/.id {
  width: 5%;
}
/deep/.code {
  width: 5%;
}
/deep/.status {
  width: 5%;
}
/deep/.info {
  width: 10%;
}
</style>
