<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <h6>
      Creation of labels that bring the user loyalty points when entering on
      Medisplus.
    </h6>
    <div class="d-flex align-items-center justify-content-end col-6 px-0">
      <b-input-group class="col-4 px-0">
        <b-input-group-append>
          <div class="m-input__icon left">
            <i class="far fa-search"></i>
          </div>
        </b-input-group-append>
        <b-form-input
          id="search"
          v-model="searchQuery"
          type="text"
          size="sm"
          placeholder="Search codes"
          class="border-left-0 pl-0 py-2 mr-2"
          debounce="500"
        ></b-form-input>
      </b-input-group>
      <b-button variant="primary" class="mr-2" @click="createNew"
        ><i class="far fa-plus mr-2"></i>Create new</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "CodeGeneratorSearch",
  data() {
    return {
      downloading: false,
    };
  },
  computed: {
    searchQuery: {
      get() {
        return this.$store.state.codeGeneratorTable.searchQuery;
      },
      set(value) {
        this.$store.commit("codeGeneratorTable/SET_CURRENT_PAGE", 1);
        this.$store.commit("codeGeneratorTable/SET_SEARCH_QUERY", value);
        this.$store.dispatch("codeGeneratorTable/getCodeBatches");
      },
    },
    dateRange: {
      get() {
        return this.$store.state.codeGeneratorTable.dateRange;
      },
      set(value) {
        this.$store.commit("codeGeneratorTable/SET_DATE_RANGE", value);
        this.$store.dispatch("codeGeneratorTable/getCodeBatches");
      },
    },
  },
  methods: {
    getDate(dateRange) {
      return `${this.$options.filters.date(
        dateRange.start
      )} - ${this.$options.filters.date(dateRange.end)}`;
    },
    clearDate() {
      this.dateRange = {
        start: null,
        end: null,
      };
    },

    createNew() {
      this.$bvModal.show("create-code-batch");
    },
  },
};
</script>

<style scoped></style>
