<template>
  <div class="p-4">
    <code-generator-search />
    <code-generator-table />
    <create-code-batch />
    <edit-code-batch />
    <code-generator-pagination />
  </div>
</template>

<script>
import CodeGeneratorSearch from "../components/code-generator/CodeGeneratorSearch";
import CreateCodeBatch from "../components/code-generator/modals/CreateCodeBatch";
import CodeGeneratorTable from "../components/code-generator/CodeGeneratorTable";
import EditCodeBatch from "../components/code-generator/modals/EditCodeBatch";
import CodeGeneratorPagination from "../components/code-generator/CodeGeneratorPagination";
export default {
  name: "MCodeGenerator",
  components: {
    CodeGeneratorPagination,
    EditCodeBatch,
    CodeGeneratorTable,
    CreateCodeBatch,
    CodeGeneratorSearch,
  },
  async created() {
    await this.$store.dispatch("codeGeneratorTable/getCodeGeneratorsMeta");
    this.$store.commit("productsTable/SET_PER_PAGE", 5);
    await this.$store.dispatch("productsTable/getProducts");
  },
};
</script>

<style scoped></style>
