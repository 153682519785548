<template>
  <b-table
    :items="items"
    :fields="fields"
    :busy="fetchingBatches"
    :sort-compare-options="{ ignorePunctuation: true, numeric: true }"
    borderless
    sticky-header="72vh"
    responsive="true"
    class="m-table__wrapper"
    table-class="m-table"
    thead-class="m-table__header"
    thead-tr-class="m-table__header-tr"
    tbody-class="m-table__body"
    :tbody-tr-class="rowClass"
  >
    <template #cell(select)="{ item }">
      <b-form-checkbox
        :value="item.selected"
        @change="toggleSelected(item, $event)"
      />
    </template>

    <template #cell(name)="{ value, item }">
      <i
        v-if="item.sentForPrinting"
        class="fas fa-exclamation-triangle text-yellow-color"
        v-b-tooltip="'Code batch was sent to printing'"
      ></i>
      {{ value }}
    </template>

    <template #cell(actions)="{ item }">
      <div class="d-flex justify-content-end">
        <b-button
          variant="action"
          v-b-tooltip.hover="'Download CSV'"
          @click="download(item)"
          class="mr-1"
        >
          <i class="fad fa-download"></i>
        </b-button>
        <b-button
          variant="action"
          v-b-tooltip.hover="'Edit code batch'"
          @click="edit(item)"
        >
          <i class="fad fa-pencil-alt"></i>
        </b-button>
      </div>
    </template>
  </b-table>
</template>

<script>
export default {
  name: "CodeGeneratorTable",
  data() {
    return {
      fields: [
        {
          key: "select",
          sortable: false,
          tdClass: "select",
          thClass: "select",
          label: "",
        },
        {
          key: "name",
          sortable: true,
          tdClass: "name",
          thClass: "name",
        },
        {
          key: "product.name",
          label: "Product",
          sortable: true,
          tdClass: "product",
          thClass: "product",
        },
        {
          key: "createdAt",
          label: "Date of creation",
          sortable: true,
          tdClass: "tokens",
          thClass: "tokens",
          formatter: (value) => {
            return this.$options.filters.date(value);
          },
        },
        {
          key: "quantity",
          label: "Quantity",
          sortable: true,
          tdClass: "quantity",
          thClass: "quantity",
        },
        {
          key: "used",
          label: "Used",
          sortable: true,
          tdClass: "used",
          thClass: "used",
        },
        {
          key: "actions",
          label: "",
          sortable: true,
          tdClass: "actions",
          thClass: "actions",
        },
      ],
    };
  },
  computed: {
    items() {
      return this.$store.state.codeGeneratorTable.batches;
    },
    fetchingBatches() {
      return this.$store.state.codeGeneratorTable.requesting;
    },
  },
  async created() {
    await this.getItems();
  },
  methods: {
    async getItems() {
      await this.$store.dispatch("codeGeneratorTable/getCodeBatches");
    },
    edit(item) {
      this.$store.commit("codeGenerator/SET_BATCH", item);
      this.$store.commit("codeGeneratorTable/SET_CODES", item.codes);
      this.$store.dispatch("codeGeneratorTable/getSelectedBatchCodes", item.id);

      this.$bvModal.show("edit-code-batch");
    },
    async download(item) {
      try {
        this.downloading = true;
        const res = await this.$axios.get("/codes/export/" + item.id, {
          responseType: "blob",
        });
        this.downloadFile(res.data, item.name);
      } catch (e) {
        console.error(e);
      } finally {
        this.downloading = false;
      }
    },
    toggleSelected(item, selected) {
      this.$store.commit("codeGeneratorTable/SET_BATCH_SELECTED", {
        batch: item,
        isSelected: selected,
      });
      console.log(item, selected);
    },
    rowClass(item, type) {
      let className = "";
      if (item && type === "row") {
        if (item.sentForPrinting) className = "inactive";
      }
      return "m-table__body-tr " + className;
    },
  },
};
</script>

<style scoped>
/deep/.select {
  width: 5%;
}
/deep/.actions {
  width: 5%;
}
/deep/.quantity {
  width: 5%;
}
/deep/.used {
  width: 5%;
}
/deep/.name {
  width: 20%;
}
/deep/.product {
  width: 35%;
}
</style>
