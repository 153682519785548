<template>
  <b-modal
    id="create-code-batch"
    centered
    size="xl"
    modal-class="m-modal"
    header-class="m-modal__header"
    footer-class="m-modal__footer"
    body-class="m-modal__body"
    @hidden="resetState"
  >
    <template #modal-header>
      <h4 class="m-modal__title">Label creation</h4>
    </template>

    <div class="d-flex align-items-center info-box info mb-4">
      <i class="fas fa-info-circle icon mr-3"></i>
      <p class="mb-0">
        The user obtains loyalty points when entering the code on the label. Use
        the input fields below to select the product and the number of labels
        you want to create.
      </p>
    </div>
    <validation-observer ref="createCodeBatch" class="d-flex flex-wrap w-100">
      <validation-provider
        tag="div"
        name="name"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Name</span>
        <b-form-input
          v-model="name"
          type="text"
          size="sm"
          :disabled="requesting"
        ></b-form-input>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="product"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pl-0"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Product</span>

        <multiselect
          v-model="product"
          :options="filteredProducts"
          placeholder="Type to search"
          searchable
          :internal-search="false"
          @search-change="searchProducts"
          :loading="false"
        >
          <template slot="singleLabel" slot-scope="props">
            <span>{{ props.option.name }}</span>
            <span class="small ml-2">{{ props.option.sku }}</span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span>{{ props.option.name }}</span>
              <span class="small ml-2">{{ props.option.sku }}</span>
            </div>
          </template>
        </multiselect>

        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <span class="col-12 ml-0 pl-0"
        >Current loyalty point value for selected product:
        <strong>{{ product ? product.tokens : "-" }}</strong></span
      >

      <validation-provider
        tag="div"
        name="codePattern"
        rules="required"
        class="d-flex flex-column mb-3 col-6 pl-0 mt-3"
        v-slot="{ errors }"
      >
        <span class="m-input__label is-required">Code pattern</span>
        <b-form-select v-model="codePattern" size="sm" class="col-12 py-3">
          <b-form-select-option
            v-for="(pattern, index) in codePatterns"
            :key="index"
            :value="pattern"
            ><strong>{{ pattern.name }}</strong> |
            {{ pattern.displayText }}</b-form-select-option
          >
        </b-form-select>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
      <validation-provider
        tag="div"
        name="numberOfLabels"
        rules="required"
        class="d-flex flex-row align-items-center mt-1 col-12 pl-0"
        v-slot="{ errors }"
      >
        <span class="">I want to create </span>
        <b-form-input
          v-model="numberOfLabels"
          type="number"
          size="sm"
          class="col-2 mx-1"
          :disabled="requesting"
        ></b-form-input>
        <span> labels</span>
        <span class="text-danger error">{{ errors[0] }}</span>
      </validation-provider>
    </validation-observer>
    <template #modal-footer="{ close }">
      <div class="d-flex align-items-center w-100">
        <div class="d-flex align-items-center ml-auto">
          <b-button
            variant="secondary"
            class="mr-2"
            :disabled="requesting"
            @click="close"
            >Close</b-button
          >
          <b-button variant="primary" :disabled="requesting" @click="validate"
            >Save
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "CreateCodeBatch",
  computed: {
    requesting() {
      return this.$store.state.codeGeneratorTable.requesting;
    },
    products() {
      return this.$store.state.codeGeneratorTable.products;
    },
    codePatterns() {
      return this.$store.state.codeGeneratorTable.codePatterns;
    },
    name: {
      get() {
        return this.$store.state.codeGenerator.batch.name;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "name",
          value,
        });
      },
    },
    product: {
      get() {
        return this.$store.state.codeGenerator.batch.product;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "product",
          value,
        });
      },
    },
    numberOfLabels: {
      get() {
        return this.$store.state.codeGenerator.batch.quantity;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "quantity",
          value,
        });
      },
    },
    codePattern: {
      get() {
        return this.$store.state.codeGenerator.batch.codePattern;
      },
      set(value) {
        this.$store.commit("codeGenerator/UPDATE_BATCH_FIELDS", {
          field: "codePattern",
          value,
        });
      },
    },
  },
  methods: {
    validate() {
      this.$refs.createCodeBatch.validate().then(async (success) => {
        if (success) {
          await this.create();
        }
      });
    },
    async create() {
      try {
        this.$store.commit("codeGenerator/SET_REQUESTING", true);
        const res = await this.$store.dispatch("codeGenerator/createBatch");
        this.$store.commit("codeGeneratorTable/ADD_BATCH", res.data.data);
        this.$bvModal.hide("create-code-batch");
        this.$bvToast.toast("Batch created", {
          title: "Code Generator",
          variant: "success",
        });
      } catch (e) {
        console.error(e);
        this.$bvToast.toast("An error occurred while creating the reward", {
          title: "Code Generator",
          variant: "danger",
        });
      } finally {
        this.$store.commit("codeGenerator/SET_REQUESTING", false);
      }
    },
    resetState() {
      this.$store.commit("codeGenerator/RESET_STATE");
    },
    searchProducts(value) {
      this.filteredProducts = this.products.filter((el) => {
        return (
          el.name.toLowerCase().includes(value.toLowerCase()) ||
          el.sku.toLowerCase().includes(value.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      filteredProducts: [],
    };
  },
  watch: {
    products(value) {
      this.$set(this, "filteredProducts", value);
    },
  },
};
</script>

<style scoped></style>
